import React, { useState, useEffect, Fragment, ReactNode } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getPaymentMethodDebit, allPersons } from 'libs/helpers';
import { dependantHelpers } from 'libs/dependant';
import { safariDateScrub } from 'libs/browserSupport';
import moment from 'moment';
import { IoMdInformationCircle } from 'react-icons/all';
import { setPaymentMethodModalVisible } from 'store';
import { StoreType } from 'store/types';

const MembershipMessages = () => {
    const membership = useSelector((state: StoreType) => state.membership);
    const dispatch = useDispatch();
    const [showMessages, setShowMessages] = useState(false);
    const [messagesArray, setMessagesArray] = useState<ReactNode[]>([]);
    const settings = useSelector((state: StoreType) => state?.settings);
    const debitAccounts = useSelector((state: StoreType) => state.paymentMethods?.debitAccounts);
    const dependantEndAge = settings?.general?.dependant_age;
    const account = debitAccounts?.[0];

    useEffect(() => {
        {
            /*
            Turning 21:
            “Name is about to turn 21. Click here to view Name’s coverage options. If we don’t hear from you, Name will no longer be covered on your policy from their birthday”.
            --------------------------
            Turning 25:
            “Name is about to turn 25 and will no longer be covered on your policy from their birthday. If Name is interested in taking out cover, they can review our options here or contact us on 1300 360 701. We can also arrange a call back if you provide us with Name’s email address and phone number by clicking here to discuss cover options”.

            Link for here: website quote/home page

            Clicking here to discuss cover options: should create a lead in Sugar, please collect Dependant’s email and phone number.

            Below Sugar fields should be populated as:
            Lead source: Website
            How did you hear about us: 25 Years old Ex Dependent
            --------------------------
        */
        }
        if (membership && Object.keys(membership).length > 0) {
            const tempMessages: JSX.Element[] = [];
            const paymentMethod = getPaymentMethodDebit(membership);
            if (paymentMethod === 'credit card') {
                                const ccYear =
                    account?.chargeCard?.expiryYear;
                //NOTE: safariDateScrub adds a leading zero to numbers less than ten
                // safari will parse dates like "2020-9", "2012-11-3", etc... as invalid
                const ccMonth = safariDateScrub(
                    account?.chargeCard
                        ?.expiryMonth,
                );
                const ccExpiry = `${ccYear}-${ccMonth}`;
                const ccTime = moment(ccExpiry).endOf('month').isAfter();
                const ccTimeMonth = moment(ccExpiry).endOf('month');
                const nowMonth = moment().endOf('month');
                if (ccTime === false) {
                    const ccMessage = (
                        <div className="error" key="ccexpired">
                            <IoMdInformationCircle
                                size={20}
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            <strong>Credit Card expired</strong> click{' '}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        setPaymentMethodModalVisible(true),
                                    );
                                }}>
                                here
                            </a>{' '}
                            to update your Credit Card information.
                        </div>
                    );
                    tempMessages.push(ccMessage);
                }
                if (ccTimeMonth.diff(nowMonth, 'months') <= 1) {
                    const ccMessage = (
                        <div className="error" key="ccexpiring">
                            <IoMdInformationCircle
                                size={20}
                                style={{ verticalAlign: 'middle' }}
                            />{' '}
                            <strong>Credit Card expiring soon</strong> click{' '}
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(
                                        setPaymentMethodModalVisible(true),
                                    );
                                }}>
                                here
                            </a>{' '}
                            to update your Credit Card information.
                        </div>
                    );
                    tempMessages.push(ccMessage);
                }
            }

            const allPeople = allPersons(membership);
            const dependantCheck = dependantHelpers.getExpiringDependents(allPeople, parseInt(dependantEndAge));
            console.log({ dependantCheck });
            if (dependantCheck.length > 0) {
                dependantCheck.map((person, idx) => {
                    const firstname = person.Person.GivenName;
                    if (person?.isTwentyOneSoon) {
                        tempMessages.push(
                            <div className="error" key={idx}>
                                <IoMdInformationCircle
                                    size={20}
                                    style={{ verticalAlign: 'middle' }}
                                />{' '}
                                <strong>
                                    {firstname} is about to turn 21.
                                </strong>{' '}
                                Click{' '}
                                <a
                                    href="https://unionhealth.com.au/"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    here
                                </a>{' '}
                                to view {firstname}&apos;s coverage options. If
                                we don&apos;t hear from you, {firstname} will no
                                longer be covered on your policy from their
                                birthday.
                            </div>
                        );
                    }
                    if (person?.isThirtyOneSoon) {
                        tempMessages.push((
                            <div className="error" key={idx}>
                                <IoMdInformationCircle
                                    size={20}
                                    style={{ verticalAlign: 'middle' }}
                                />{' '}
                                <strong>
                                    {firstname} is about to turn {dependantEndAge} and will no
                                    longer be covered on your policy from their
                                    birthday.
                                </strong>{' '}
                                If {firstname} is interested in taking out
                                cover, they can review our options{' '}
                                <a
                                    href="https://unionhealth.com.au/"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    here
                                </a>{' '}
                                or contact us on 1300 611 283. We can also
                                arrange a call back if you provide us with{' '}
                                {firstname}&apos;s email address and phone
                                number by clicking{' '}
                                <a
                                    href="https://unionhealth.com.au/extended-dependant"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    here
                                </a>{' '}
                                to discuss cover options
                            </div>
                        ));
                    }
                });
            }

            if (tempMessages.length > 0) {
                setMessagesArray([tempMessages]);
                setShowMessages(true);
            } else {
                setShowMessages(false);
            }
        }
    }, [membership, account]);

    if (!showMessages) {
        return <div className="d-none"></div>;
    }

    return (
        <div className="membership-messages">
            {messagesArray.map((item, idx) => {
                return <Fragment key={idx}>{item}</Fragment>;
            })}
        </div>
    );
};

export default MembershipMessages;
