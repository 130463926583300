import Cancel from 'components/elements/buttons/Cancel';
import CustomModal from 'components/modals/Modal';
import { getAccessToken, getMembershipId } from 'libs/auth';
import { CONFIG } from 'libs/constants';
import gtagEvent from 'libs/gtagEvent';
import { allPersons, convertToArray } from 'libs/helpers';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useAsyncFn } from 'react-use';
import { fastClaimActions } from 'store';
import api, { MembershipPersonType } from '@qldtuh/tuh-uh-js-api';
import {
    MIN_PBS_ERROR,
    NOT_COVERED_BY_POLICY_ERROR,
    OUTSIDE_FEE_RANGE_ERROR,
} from '../common/constants';
import {
    confirmProvider,
    detectDuplicateItem,
    disableAddItemButton,
    getMedicareMessage,
    injectProvider,
    resetClaimItems,
} from '../common/functions';
import {
    BodyPartRequired,
    Claimant,
    Fee,
    Item,
    LastUsedProviders,
    ProviderSearch,
    Service,
    ServiceDate,
    StatusNotifications,
} from '../components';
import { StoreType } from 'store/types';
import { FastClaimTypeItem } from 'types/fastClaim';

interface AddClaimItemModalProps {
    show: boolean;
    handleClose: () => void;
    align: string;
}

const AddClaimItemModal: React.FC<AddClaimItemModalProps> = ({ show, handleClose, align }) => {
    const membership = useSelector((state: StoreType) => state.membership);
    const specialties = useSelector((state: StoreType) => state.claimSpeciality);
    const persons: MembershipPersonType[] = allPersons(membership);
    const appSettings = useSelector((state: StoreType) => state.settings);
    const lastUsedProviders = useSelector((state: StoreType) => state.lastProviders);
    const fastClaimStore = useSelector((state: StoreType) => state.fastClaim);
    const dispatch = useDispatch();
    let abortController = new AbortController();

    const [errorSpace, setErrorSpace] = useState('');
    const [failedAttempts, setFailedAttempts] = useState(0);

    const [itemObject, setItemObject] = useState<FastClaimTypeItem>();
    const [providerAutocomplete, setProviderAutocomplete] = useState([]);
    const [serviceItems, setServiceItems] = useState<any[]>([]);
    const [bodyPartText, setBodyPartText] = useState('');
    const [feeCorrect, setFeeCorrect] = useState(false);
    // const [selectedLastProvider, setSelectedLastProvider] = useState('');
    const [usingLastProviders, setUsingLastProviders] = useState(false);
    const [medicareMessage, setMedicareMessage] = useState('');

    const [hideDropdown, setHideDropdown] = useState(false);

    useEffect(() => {
        const mainPerson = persons.find((person) => person.RelationshipCode == 'Membr' )
        // Make new item
        if (show) setItemObject({ 
            id: Date.now().toString(), 
            personId: persons.length === 1 ? persons[0].Person.PersonId.toString() : mainPerson?.Person.PersonId.toString(),
        });
    }, [show]);

    useEffect(() => {
        if (
            itemObject?.provider?.providerid &&
            !itemObject?.item?.ServiceCode
        ) {
            providerItems(
                itemObject?.provider?.providerid,
                itemObject?.serviceTypeCode,
                itemObject,
            );
        }
        if (
            itemObject?.tempproviderid === '' ||
            !itemObject?.item?.ServiceCode
        ) {
            setServiceItems([]);
        }
        if (itemObject?.itemFee) {
            const pbsAmount = parseFloat(appSettings?.general?.pbs_amount);
            const tempFee = parseFloat(itemObject?.itemFee);
            if (itemObject?.item?.ServiceCode === 'PHMCY') {
                if (tempFee < pbsAmount) {
                    setErrorSpace(`${MIN_PBS_ERROR} ${pbsAmount}`);
                    setFailedAttempts(1);
                    setFeeCorrect(false);
                } else {
                    setFailedAttempts(0);
                    setErrorSpace('');
                    setFeeCorrect(true);
                }
            } else {
                if (tempFee >= 10 && tempFee <= 500) {
                    setFailedAttempts(0);
                    setErrorSpace('');
                    setFeeCorrect(true);
                } else {
                    setErrorSpace(OUTSIDE_FEE_RANGE_ERROR);
                    setFailedAttempts(1);
                    setFeeCorrect(false);
                }
            }
        }
    }, [itemObject, appSettings]);

    const autocompleteCallback = async (value, speciality, itemObject) => {
        abortController.abort(); // Cancel the previous request
        abortController = new AbortController();

        const access_token = getAccessToken();
        const providerID = value;

        setProviderAutocomplete([]);
        setServiceItems([]);
        setHideDropdown(false);
        if (providerID === '') return;

        try {
            const response = await api
                .provider(CONFIG, access_token)
                .search(providerID, speciality, abortController.signal);

            if (response?.status === 'error')
                throw new Error(response.message);
            if (response.length < 1) setProviderAutocomplete([]);
            if (confirmProvider(response, providerID)) {
                const cleanObject = resetClaimItems(itemObject);
                const newObject = injectProvider(cleanObject, response[0]);
                setItemObject(newObject);
                setProviderAutocomplete([]);
                setHideDropdown(true);
            } else {
                setProviderAutocomplete(response);
                setServiceItems([]);
            }
        } catch (err) {
            if (err instanceof Error) {
                if (err.name === 'AbortError') {
                    return; // Continuation logic has already been skipped, so return normally
                }
                console.log('ERROR', err, { autocompleteProviderState });
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setProviderAutocomplete([]);
            setServiceItems([]);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    }

    const providerItemsCallback = async (providerID, speciality, itemObject) => {
        const memberid = getMembershipId();
        const access_token = getAccessToken();

        try {
            if (!memberid) throw new Error('No member ID found');
            const response = await api
                .provider(CONFIG, access_token)
                .claimServices(
                    memberid,
                    providerID,
                    speciality,
                    itemObject.dateOfService,
                );
            if (response?.status === 'error')
                throw new Error(response.message);

            const serviceLineItems = convertToArray(response);
            if (serviceLineItems.length === 0) {
                setErrorSpace(NOT_COVERED_BY_POLICY_ERROR);
                setFailedAttempts(1);
                setTimeout(() => {
                    setFailedAttempts(0);
                    setErrorSpace('');
                }, 10000);
            } else {
                setServiceItems(serviceLineItems);
            }
        } catch (err) {
            if (err instanceof Error) {
                console.log('ERROR', err, { providerItemsState });
                setErrorSpace(err.message);
            }
            setFailedAttempts(1);
            setTimeout(() => {
                setErrorSpace('');
                setFailedAttempts(0);
            }, 10000);
        }
    }

    const [autocompleteProviderState, autocompleteProvider] = useAsyncFn<any,any>(autocompleteCallback);

    const [providerItemsState, providerItems] = useAsyncFn<any,any>(providerItemsCallback);

    const handleClaimantChange = (event) => {
        if (event.target.value === '') setMedicareMessage('');
        setUsingLastProviders(false);
    };

    const handleServiceChange = (benefitService) => {
        setBodyPartText(
            benefitService?.SpecialityClassCode === 'Den'
                ? 'Tooth number'
                : 'Script number',
        );
        const message = getMedicareMessage(
            benefitService,
            appSettings.general.pbs_amount,
        );
        setMedicareMessage(message);
    };

    const handleLastUsedProviderChange = (event) => {
        setUsingLastProviders(event.target.value !== '');
        setProviderAutocomplete([]);
        setServiceItems([]);
        // setSelectedLastProvider(event.target.value);
    };

    const handleServiceItemChange = (serviceItem) => {
        const medicareMessage = getMedicareMessage(
            serviceItem,
            appSettings.general.pbs_amount,
        );

        setMedicareMessage(medicareMessage);
    };

    return (
        <CustomModal
            handleClose={() => handleClose()}
            align={align}
            show={show}
            title={'Add Claim Item'}>
            <div className={'font-feature row row-cols-1'}>
                <Form>
                    <div
                        className="d-flex pb-3"
                        style={{
                            boxShadow: 'inset 0px -2px 0px #E6E6EE',
                        }}>
                        <ServiceDate
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                        />
                        <Claimant
                            persons={persons}
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                            handleChange={handleClaimantChange}
                        />
                    </div>
                    <div
                        className="pt-3 pb-3"
                        style={{
                            boxShadow: 'inset 0px -2px 0px #E6E6EE',
                        }}>
                        <Service
                            specialties={specialties}
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                            handleChange={handleServiceChange}
                        />
                        <div className="col-12">
                            <LastUsedProviders
                                lastUsedProviders={lastUsedProviders}
                                // selectedLastProvider={selectedLastProvider}
                                claimItem={itemObject}
                                updateClaim={setItemObject}
                                handleChange={handleLastUsedProviderChange}
                            />
                        </div>
                        <div className="col-12">
                            <ProviderSearch
                                providerList={providerAutocomplete}
                                useLastProvider={usingLastProviders}
                                claimItem={itemObject}
                                updateClaim={setItemObject}
                                autocompleteProvider={autocompleteProvider}
                                hideDropdown={hideDropdown}
                            />
                        </div>
                        <div
                            className="col-12 font-12 font-weight-bold pb-2"
                            style={{
                                display: (itemObject?.provider?.providerid)?'block':'none'
                            }}
                        >
                            <div>{itemObject?.provider?.providerid} - {itemObject?.provider?.title} {itemObject?.provider?.surname}</div>
                            <div>{itemObject?.provider?.suburb}, {itemObject?.provider?.state}</div>
                        </div>
                        <Item
                            serviceItems={serviceItems}
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                            handleChange={handleServiceItemChange}
                        />
                    </div>
                    <div
                        className="d-flex py-3"
                        style={{
                            boxShadow: 'inset 0px -2px 0px #E6E6EE',
                        }}>
                        <Fee
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                        />
                        <BodyPartRequired
                            bodyPartText={bodyPartText}
                            claimItem={itemObject}
                            updateClaim={setItemObject}
                        />
                    </div>
                    <div className="col-12 py-3">
                        <Button
                            variant="secondary"
                            onClick={() => {
                                gtagEvent({
                                    screen: 'addclaimitem',
                                    action: 'submit',
                                    label: 'added item to fast claim',
                                    type: 'modal',
                                });
                                dispatch(
                                    fastClaimActions.item.add(itemObject?.id),
                                );
                                dispatch(
                                    fastClaimActions.item.update(
                                        itemObject?.id,
                                        itemObject,
                                    ),
                                );
                                dispatch(
                                    fastClaimActions.item.set.currentItem(null),
                                );
                                handleClose();
                            }}
                            disabled={
                                disableAddItemButton(itemObject, feeCorrect) ||
                                detectDuplicateItem(
                                    itemObject,
                                    fastClaimStore.items,
                                )
                            }
                            className="mr-3">
                            Add Item
                        </Button>

                        <Cancel
                            style={{
                                color: '#9998A8',
                            }}
                            onClick={() => {
                                handleClose();
                            }}
                        />
                    </div>
                    <div style={{ position: 'relative' }}>
                        <StatusNotifications
                            errorSpace={errorSpace}
                            medicareMessage={medicareMessage}
                            failedAttempts={failedAttempts}
                            isDuplicate={detectDuplicateItem(
                                itemObject,
                                fastClaimStore.items,
                            )}
                        />
                    </div>
                </Form>
            </div>
        </CustomModal>
    );
};

export default AddClaimItemModal;
